import { useEffect, useState } from "react";

import { Routes, Route } from "react-router-dom";
import { LoginPage } from "./pages/Login";
import { MainLayout } from "./layouts/mainLayout";
import { Header } from "./layouts/Header";
import { Footer } from "./layouts/Footer";
import Protected from "./protected";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "./css/style.css";

function App() {
  const [token, setToken] = useState("");
  useEffect(() => {
    let data = {
      username: "jayraj",
      password: "jayraj@2000",
      // username: "guest",
      // password: "123123",
    };
    fetch("https://rest.wadelivery.com/api/v1/customer/business/auth/login", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        localStorage.setItem("token", JSON.stringify(data.data.accessToken));
        localStorage.setItem("id", data.data.id);
        setToken(data.data.accessToken);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  return (
    <>
      <Header />

      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<Protected Component={MainLayout} />} />
        <Route index element={<Protected Component={MainLayout} />} />
      </Routes>
      <Footer />
    </>
  );
}
export default App;
