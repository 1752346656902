export const Footer = () => {
  return (
    <>
      <section id="boxes" className="boxes padding-100">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12 center-text">
              <h2>
                INDIA’S FIRST & ONLY SAME DAY EV DELIVERY NETWORK
                <br /> REINVETING LAST-MILE DELIVERY
              </h2>
            </div>

            <div className="col-md-4 col-12">
              <div className="box box-a" data-aos="fade-up">
                <div className="icon">
                  <span className="lnr">
                    <i className="fas fa-history"></i>
                  </span>
                </div>
                <div className="space-20"></div>
                <h4>Same-day delivery</h4>
                <div className="space-15"></div>
                <p>
                  Express same-day delivery you can rely on. Getting your parcel
                  to where it needs to be by close of business, the same day.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div
                className="box box-b"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="icon">
                  <span className="lnr">
                    <i className="fas fa-dice-three"></i>
                  </span>{" "}
                </div>
                <div className="space-20"></div>
                <h4>Simple 3 click process</h4>
                <div className="space-15"></div>
                <p>
                  wa! app makes booking your order easy and allows you to track
                  the progress of your delivery.
                  <br />
                  <br />
                </p>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div
                className="box box-a"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div className="icon">
                  <span className="lnr">
                    <i className="fab fa-hubspot"></i>
                  </span>{" "}
                </div>
                <div className="space-20"></div>
                <h4>Micro and mobile hubs</h4>
                <div className="space-15"></div>
                <p>
                  wa! uses micro and mobile hubs in key target cities for
                  efficient same day deliveries.
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="spacer"></div>

        <div className="container">
          <div className="row">
            <div className="col-md-4 col-12">
              <div className="box box-b" data-aos="fade-up">
                <div className="icon">
                  <span className="lnr">
                    <i className="fab fa-pagelines"></i>
                  </span>{" "}
                </div>
                <div className="space-20"></div>
                <h4>100% Sustainable & EV fleet</h4>
                <div className="space-15"></div>
                <p>
                  wa! operates a range of electric vehicles to make same day
                  deliveries environmental friendly.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div
                className="box box-a"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="icon">
                  <span className="lnr">
                    <i className="fas fa-cogs"></i>
                  </span>{" "}
                </div>
                <div className="space-20"></div>
                <h4>Easy to use on demand platform</h4>
                <div className="space-15"></div>
                <p>
                  Simple, intuitive platform which makes it easy for you to do
                  your bookings in a hassle-free manner.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div
                className="box box-b"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div className="icon">
                  <span className="lnr">
                    <i className="fas fa-route"></i>
                  </span>{" "}
                </div>
                <div className="space-20"></div>
                <h4>Unique optimised of routes</h4>
                <div className="space-15"></div>
                <p>
                  State of the art, AI driven, route optimisation allows us to
                  meet our same day delivery commitments.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section
        id="other_features"
        className="other-features padding-100 background-withcolor"
      >
        <div className="container-fluid">
          <div className="row">
            <div
              className="text-center col-12 section-title"
              data-aos="fade-zoom-in"
            >
              <h3>
                <span className="white">
                  {" "}
                  Our solution designed for your business to excel{" "}
                </span>{" "}
              </h3>
              <div className="space-25"></div>
              <p style={{ color: "#54264d" }}>
                We have taken pains to understand your Key success parameter
              </p>
              <div className="space-50"></div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-12">
              <div className="other-features-slider" data-aos="fade-up">
                <div className="item text-center">
                  <div className="icon">
                    <span className="lnr">
                      <i className="fas fa-bezier-curve"></i>
                    </span>
                  </div>

                  <h4>Collection in 60 minutes</h4>
                  <p>Get your package collected in an hour or less</p>
                </div>
                <div className="item text-center">
                  <div className="icon">
                    <span className="lnr">
                      <i className="fas fa-wallet"></i>
                    </span>
                  </div>
                  <h4>Transparent pricing</h4>
                  <p>Simple and transparent dynamic pricing</p>
                </div>
                <div className="item text-center">
                  <div className="icon">
                    <span className="lnr">
                      <i className="fas fa-search-dollar"></i>
                    </span>
                  </div>
                  <h4>Manage your account online</h4>
                  <p>
                    Log on with your computer, tablet or smartphone or use wa!
                    delivery app on your phone
                  </p>
                </div>
                <div className="item text-center">
                  <div className="icon">
                    <span className="lnr">
                      <i className="fas fa-bezier-curve"></i>
                    </span>
                  </div>

                  <h4>API-live tracking</h4>
                  <p>Keep track of your on-demand packages in real time</p>
                </div>
                <div className="item text-center">
                  <div className="icon">
                    <span className="lnr">
                      <i className="fas fa-wallet"></i>
                    </span>
                  </div>
                  <h4>Receive electronic PODs</h4>
                  <p>
                    Images of the POD are available to the customer, such as the
                    signature and photo the driver created
                  </p>
                </div>
                <div className="item text-center">
                  <div className="icon">
                    <span className="lnr">
                      <i className="fas fa-search-dollar"></i>
                    </span>
                  </div>
                  <h4>Flexible solutions suitable for all</h4>
                  <p>
                    Business or personal bookings, single or several items, one
                    stop or multiple drops, your delivery needs are covered
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section id="become-partner" className="main-features padding-100">
        <div className="container">
          <div className="row">
            <div
              className="text-center col-12 section-title"
              data-aos="fade-zoom-in"
            >
              <h3>
                <span>wa! Partners Programme</span>{" "}
              </h3>
              <div className="space-25"></div>
              <p>Become a part of the wa! Delivery</p>
              <div className="space-25"></div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-3 text-lg-right left-side">
              <div
                className="one-feature one"
                data-aos="fade-right"
                data-aos-delay="1000"
              >
                <h5>Join the EV Revolution</h5>

                <p>Feel good to be part of the green movement</p>
              </div>
              <div
                className="one-feature"
                data-aos="fade-right"
                data-aos-delay="1400"
              >
                <h5>Earn a fixed competitive salary</h5>

                <p>
                  Reach your earning goals with variables
                  <br />
                  Access to competitive benefits{" "}
                </p>
              </div>
              <div
                className="one-feature"
                data-aos="fade-right"
                data-aos-delay="1800"
              >
                <h5>Support, training and peace of mind</h5>

                <p>
                  Our support teams are always with you. We offer safety
                  training and equipment, too, as well as free accident cover
                  while you’re on the road.
                </p>
              </div>
            </div>
            <div className="col-lg-6 text-center">
              <div className="features-circle">
                <div
                  className="circle-svg"
                  data-aos="zoom-in"
                  data-aos-delay="400"
                >
                  <svg
                    version="1.1"
                    id="features_circle"
                    xmlns="http://www.w3.org/2000/svg"
                    // xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="543px"
                    height="542.953px"
                    viewBox="0 0 543 542.953"
                    enableBackground="new 0 0 543 542.953"
                    // xml:space="preserve"
                  >
                    <g>
                      <circle
                        fill="none"
                        stroke="#"
                        strokeWidth="3"
                        strokeMiterlimit="10"
                        strokeDasharray="11.9474,11.9474"
                        cx="271.5"
                        cy="271.516"
                        r="270"
                      />
                      <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0"
                        to="360"
                        dur="50s"
                        repeatCount="indefinite"
                      ></animateTransform>
                    </g>
                  </svg>
                </div>
                <a href="#">
                  <img
                    data-aos="fade-up"
                    data-aos-delay="200"
                    src="assets/img/mobile-2.png"
                    className="img-fluid"
                    alt=""
                  />
                </a>{" "}
              </div>
            </div>
            <div className="col-lg-3 right-side">
              <div
                className="one-feature"
                data-aos="fade-left"
                data-aos-delay="1000"
              >
                <h5>Flexible working environment</h5>

                <p>Flexible schedules, work when you want to</p>
              </div>
              <div
                className="one-feature"
                data-aos="fade-left"
                data-aos-delay="1400"
              >
                <h5>Choice of EVs</h5>

                <p>
                  e-bikes
                  <br />
                  e-scooter
                  <br />
                  e-3W
                </p>
              </div>
              <div
                className="one-feature"
                data-aos="fade-left"
                data-aos-delay="1800"
              >
                <h5>Free professional kit</h5>

                <p>
                  Highly visible, comfortable and breathable gear, suitable for
                  the climate you’re riding in
                </p>
              </div>
            </div>
          </div>

          <div
            className="one-feature aos-init aos-animate"
            data-aos="fade-left"
            data-aos-delay="1800"
          >
            <h5>We're always here for you</h5>

            <p>
              When you’re on the road, we’re with you – for help, advice and
              support, message or call us on the app. Your safety is our
              priority, so we’ll insure you in case of accidents, too. It’s
              totally free and applies from the moment you go online.
            </p>
          </div>
        </div>
      </section>

      <section id="faq" className="faq padding-100">
        <div className="container">
          <div className="row">
            <div
              className="text-center col-12 section-title"
              data-aos="fade-zoom-in"
            >
              <h3>
                <span>Frequently asked questions</span>{" "}
              </h3>
              <div className="space-25"></div>
              <p>
                Lorem ipsum madolor sit amet, consectetur adipisicing elit, sed
                do eiusmod tempor coli incidit labore lorem ipsum amet madolor
                sit amet.
              </p>
              <div className="space-50"></div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-8 col-12" data-aos="fade-right">
              <div className="accordion" id="faqAccordion">
                <div className="card shadow">
                  <div className="card-header" id="heading_1">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse_1"
                        aria-expanded="true"
                        aria-controls="collapse_1"
                      >
                        What are the hours of operation?
                      </button>
                    </h5>
                  </div>

                  <div
                    id="collapse_1"
                    className="collapse show"
                    aria-labelledby="heading_1"
                    data-parent="#faqAccordion"
                  >
                    <div className="card-body">
                      We deliver from 8am to 8pm Monday to Saturday
                    </div>
                  </div>
                </div>
                <div className="card shadow">
                  <div className="card-header" id="heading_2">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse_2"
                        aria-expanded="false"
                        aria-controls="collapse_2"
                      >
                        What areas does wa! delivery cover?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse_2"
                    className="collapse"
                    aria-labelledby="heading_2"
                    data-parent="#faqAccordion"
                  >
                    <div className="card-body">
                      Currently we cover the Pune- Pimpri-Chinchwad metropolitan
                      area.
                    </div>
                  </div>
                </div>
                <div className="card shadow">
                  <div className="card-header" id="heading_3">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse_3"
                        aria-expanded="false"
                        aria-controls="collapse_3"
                      >
                        Do you provide proof of delivery?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse_3"
                    className="collapse"
                    aria-labelledby="heading_3"
                    data-parent="#faqAccordion"
                  >
                    <div className="card-body">
                      Yes, we send a confirmation via notification when your
                      driver is dispatched and when the goods are collected and
                      delivered, complete with the name, time and date.
                    </div>
                  </div>
                </div>

                <div className="card shadow">
                  <div className="card-header" id="heading_3">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse_4"
                        aria-expanded="false"
                        aria-controls="collapse_4"
                      >
                        Does wa! Delivery deliver fragile goods?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse_4"
                    className="collapse"
                    aria-labelledby="heading_3"
                    data-parent="#faqAccordion"
                  >
                    <div className="card-body">
                      Yes, contact us and let us know your specific
                      requirements.
                    </div>
                  </div>
                </div>

                <div className="card shadow">
                  <div className="card-header" id="heading_3">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse_5"
                        aria-expanded="false"
                        aria-controls="collapse_5"
                      >
                        Do I need to place a minimum quantity or minimum number
                        of deliveries per month?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse_5"
                    className="collapse"
                    aria-labelledby="heading_3"
                    data-parent="#faqAccordion"
                  >
                    <div className="card-body">
                      There is no minimum order. However, contact us if you are
                      interested in a corporate account.
                    </div>
                  </div>
                </div>

                <div className="card shadow">
                  <div className="card-header" id="heading_3">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse_6"
                        aria-expanded="false"
                        aria-controls="collapse_6"
                      >
                        Do you also take care of packaging the goods?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse_6"
                    className="collapse"
                    aria-labelledby="heading_3"
                    data-parent="#faqAccordion"
                  >
                    <div className="card-body">
                      No, we don’t currently offer packaging services.
                    </div>
                  </div>
                </div>

                <div className="card shadow">
                  <div className="card-header" id="heading_3">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapse_7"
                        aria-expanded="false"
                        aria-controls="collapse_7"
                      >
                        Do I have an option to choose delivery timelines?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapse_7"
                    className="collapse"
                    aria-labelledby="heading_3"
                    data-parent="#faqAccordion"
                  >
                    <div className="card-body">Yes, for on demand.</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-4 col-12"
              data-aos="fade-left"
              data-aos-delay="400"
            >
              <img
                src="assets/img/mobile-1.png"
                className="img-fluid b-block mx-auto"
                alt=""
              />{" "}
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="why-us padding-100 background-withcolor">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 text-center" data-aos="fade-right">
              <img src="assets/img/mobile-3.png" className="img-fluid" alt="" />{" "}
            </div>
            <div
              className="col-md-6"
              data-aos="fade-zoom-in"
              data-aos-delay="200"
            >
              <h3>Make your Deliveries Clean, Green & Easy</h3>
              <p>
                We focus on making your deliveries easy & efficient so you can
                focus on your core business. Our deliveries are 100% green for
                cleaner air for all of us.
              </p>
              <br />
              <p>
                We deliver on our sustainability goals by using a variety of
                electric vehicles and the latest generation software for route
                optimisation :
              </p>
            </div>
            <div className="space-25"></div>

            <div className="row">
              <div
                className="col-lg-4col-md-4 col-sm-4 col-12 aos-init aos-animate"
                data-aos="zoom-in"
                data-aos-delay="400"
              >
                <div className="why-us-icon">
                  <img
                    src="assets/img/ebike.png"
                    className="img-fluid"
                    alt=""
                  />
                  <p>E-Cargo Bike</p>
                </div>
              </div>
              <div
                className="col-lg-4col-md-4 col-sm-4 col-12 aos-init aos-animate"
                data-aos="zoom-in"
                data-aos-delay="400"
              >
                <div className="why-us-icon">
                  <img
                    src="assets/img/escooter.png"
                    className="img-fluid"
                    alt=""
                  />
                  <p>E-Scooters</p>
                </div>
              </div>
              <div
                className="col-lg-4col-md-4 col-sm-4 col-12 aos-init aos-animate"
                data-aos="zoom-in"
                data-aos-delay="400"
              >
                <div className="why-us-icon">
                  <img src="assets/img/evan.png" className="img-fluid" alt="" />
                  <p>E-Vans</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section id="logos" className="logos background-withcolor">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="logos-slider">
                <div className="item">
                  <img
                    src="assets/img/logo-1.png"
                    className="img-fluid mx-auto b-block"
                    alt=""
                  />
                </div>
                <div className="item">
                  <img
                    src="assets/img/logo-2.png"
                    className="img-fluid mx-auto b-block"
                    alt=""
                  />
                </div>
                <div className="item">
                  <img
                    src="assets/img/logo-3.png"
                    className="img-fluid mx-auto b-block"
                    alt=""
                  />
                </div>
                <div className="item">
                  <img
                    src="assets/img/logo-4.png"
                    className="img-fluid mx-auto b-block"
                    alt=""
                  />
                </div>
                <div className="item">
                  <img
                    src="assets/img/logo-5.png"
                    className="img-fluid mx-auto b-block"
                    alt=""
                  />
                </div>
                <div className="item">
                  <img
                    src="assets/img/logo-6.png"
                    className="img-fluid mx-auto b-block"
                    alt=""
                  />
                </div>
                <div className="item">
                  <img
                    src="assets/img/logo-7.png"
                    className="img-fluid mx-auto b-block"
                    alt=""
                  />
                </div>
                <div className="item">
                  <img
                    src="assets/img/logo-8.png"
                    className="img-fluid mx-auto b-block"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section id="testimonial" className="clients-testimonial padding-100">
        <div className="container">
          <div className="row">
            <div
              className="text-center col-12 section-title"
              data-aos="fade-zoom-in"
            >
              <h3>
                Customer
                <span> Testimonial</span>{" "}
              </h3>
              <div className="space-25"></div>
              <p>
                Our aim is customer happiness. Don’t just take our word for it.
              </p>
              <div className="space-50"></div>
            </div>
            <div className="col-12">
              <div className="testimonial-slider" data-aos="fade-up">
                <div className="item">
                  <div className="client-testimonial">
                    <p>
                      Completely build backend ROI whereas cross-media metrics.
                      Collaboratively deploy customer directed web-readiness via
                      global testing procedures. Appropriately reinvent
                      distributed innovation.{" "}
                    </p>
                  </div>
                  <div className="client-info d-flex align-items-center">
                    <figure>
                      <img
                        src="assets/img/client.jpg"
                        className="img-fluid"
                        alt=""
                      />{" "}
                    </figure>
                    <div>
                      <h3>Mohamed Kamel</h3>
                      <h6>CEO - Company Name</h6>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="client-testimonial">
                    <p>
                      If you can design one thing you can design everything with
                      Front. Just believe it. Collaboratively repurpose
                      performance based e-commerce without cost. It's beautiful
                      and the coding is done quickly and seamlessly.{" "}
                    </p>
                  </div>
                  <div className="client-info d-flex align-items-center">
                    <figure>
                      <img
                        src="assets/img/client.jpg"
                        className="img-fluid"
                        alt=""
                      />{" "}
                    </figure>
                    <div>
                      <h3>Mohamed Kamel</h3>
                      <h6>CEO - Company Name</h6>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="client-testimonial">
                    <p>
                      If you can design one thing you can design everything with
                      Front. Just believe it. Collaboratively repurpose
                      performance based e-commerce without cost. It's beautiful
                      and the coding is done quickly and seamlessly.{" "}
                    </p>
                  </div>
                  <div className="client-info d-flex align-items-center">
                    <figure>
                      <img
                        src="assets/img/client.jpg"
                        className="img-fluid"
                        alt=""
                      />{" "}
                    </figure>
                    <div>
                      <h3>Mohamed Kamel</h3>
                      <h6>CEO - Company Name</h6>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="client-testimonial">
                    <p>
                      Completely build backend ROI whereas cross-media metrics.
                      Collaboratively deploy customer directed web-readiness via
                      global testing procedures. Appropriately reinvent
                      distributed innovation.{" "}
                    </p>
                  </div>
                  <div className="client-info d-flex align-items-center">
                    <figure>
                      <img
                        src="assets/img/client.jpg"
                        className="img-fluid"
                        alt=""
                      />{" "}
                    </figure>
                    <div>
                      <h3>Mohamed Kamel</h3>
                      <h6>CEO - Company Name</h6>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="client-testimonial">
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Odit fugit dignissimos nihil deleniti sunt enim cupiditate
                      quia officia suscipit est saepe atque expedita, natus
                      numquam animi inventore harum esse ut.{" "}
                    </p>
                  </div>
                  <div className="client-info d-flex align-items-center">
                    <figure>
                      <img
                        src="assets/img/client.jpg"
                        className="img-fluid"
                        alt=""
                      />{" "}
                    </figure>
                    <div>
                      <h3>Mohamed Kamel</h3>
                      <h6>CEO - Company Name</h6>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="client-testimonial">
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Odit fugit dignissimos nihil deleniti sunt enim cupiditate
                      quia officia suscipit est saepe atque expedita, natus
                      numquam animi inventore harum esse ut.{" "}
                    </p>
                  </div>
                  <div className="client-info d-flex align-items-center">
                    <figure>
                      <img
                        src="assets/img/client.jpg"
                        className="img-fluid"
                        alt=""
                      />{" "}
                    </figure>
                    <div>
                      <h3>Mohamed Kamel</h3>
                      <h6>CEO - Company Name</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="contact-us"
        className="git-in-touch padding-100 background-withcolor"
      >
        <div className="container">
          <div className="row">
            <div
              className="text-center col-12 section-title"
              data-aos="fade-zoom-in"
            >
              <h3>We’d Love to hear From You</h3>
              <div className="space-25"></div>
              <p>
                We’re here to help you and answer any questions you might have
              </p>
              <div className="space-50"></div>
            </div>
          </div>
          <form data-aos="fade-up">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Name"
                  />
                  <span className="focus-border"></span>{" "}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Your Email"
                  />
                  <span className="focus-border"></span>{" "}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Subject"
                  />
                  <span className="focus-border"></span>{" "}
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <textarea
                    className="form-control"
                    rows="4"
                    placeholder="Enter Your Message"
                  ></textarea>
                  <span className="focus-border"></span>{" "}
                </div>
              </div>
              <div className="col-12">
                <div className="space-25"></div>
                <button
                  type="submit"
                  className="btn btn-primary shadow btn-colord btn-theme"
                >
                  <span>Send Message</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>

      <footer>
        <div className="copyright">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-4">
                <p>
                  All rights reserved © <a href="#">WA</a>, 2020
                </p>
              </div>
              <div className="offset-md-4 col-md-4">
                <ul className="nav justify-content-end">
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      Terms and Conditions
                    </a>{" "}
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      Privacy Policy
                    </a>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="floating-icons-wrapper">
          <div class="floating-icons">
            <div class="floating-icons-left">DOWNLOAD APP</div>{" "}
            <div class="floating-icons-right">
              <a href="#">
                <i class="fab fa-apple"></i>
              </a>{" "}
              <a href="#">
                <i class="fab fa-google-play"></i>
              </a>
            </div>
            <div class="clear"></div>
          </div>
        </div>
      </footer>
    </>
  );
};
