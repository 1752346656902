import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { UserIcon } from "./userIcon";
export const Header = () => {
  return (
    <>
      <div className="preloader">
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      </div>

      <div className="themes-colors">
        <h3>We love your world.</h3>
        <span>
          <div className="heart"></div>
        </span>
        wa! has re-engineered the entire delivery system to be completely carbon
        free. So, you get your delivery the same day, but not at the cost of
        your world.
      </div>

      <header className="foxapp-header">
        <nav className="navbar navbar-expand-lg navbar-light" id="foxapp_menu">
          <div className="container">
            <a className="navbar-brand" href="index.html">
              <img src="assets/img/logo.png" className="img-fluid" alt="" />{" "}
            </a>

            <div className="location">
              Pune <i className="fas fa-street-view"></i>
            </div>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#main_menu"
              aria-controls="main_menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>{" "}
            </button>
            <div className="collapse navbar-collapse" id="main_menu">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a className="nav-link anchor" href="#become-partner">
                    Become a partner
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link anchor" href="#">
                    Track delivery
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link anchor" href="#">
                    Login
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link anchor" href="#contact-us">
                    Contact us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>

      {/* <Navbar bg="warning" expand="lg" className="shadow">
        <Container>
          <Navbar.Brand href="/">101 Delivery</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              Test
            </Nav>
            <div>
              <UserIcon />
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar> */}
    </>
  );
};
