import { useEffect, useState } from "react";
import { Container, Button, Row, Col, Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

//let token = JSON.parse(localStorage.getItem("token"));
export const LoginPage = () => {
  let navigate = useNavigate();

  const [user, setLogin] = useState("");
  const [options, setOptions] = useState([]);
  const [pickup, setPickup] = useState("");
  const [dropoff, setDropoff] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {
    let localuser = localStorage.getItem("user");
    let token = JSON.parse(localStorage.getItem("token"));
    setToken(token);
    if (localuser) {
      setLogin(localuser);
    }
  }, []);
  // if (user) {
  //   navigate("/", { replace: true });
  // }
  const handleSubmit = (e) => {
    e.preventDefault();

    let user = {
      mobile: e.target[0].value,
      pickup: e.target[1].value,
      dropoff: e.target[2].value,
    };

    localStorage.setItem("user", JSON.stringify(user));
    navigate("/");
  };

  const handleOnSearch = async (string, results) => {
    let localToken = JSON.parse(localStorage.getItem("token"));
    fetch(
      "https://rest.wadelivery.com/api/v1/customer/business/geocoding/autocomplete?query=" +
        string +
        "&latitude=18.6297811&longitude=73.7997094",
      {
        method: "GET", // or 'PUT'
        headers: {
          Authorization: "Bearer " + localToken,
          "Content-Type": "application/json ,text/plain, */*",
          "Accept-Language": "en",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setOptions([]);

        console.log("Success:", data.data);
        // setServices(data.data);
        let newOptions = [];
        data.data.map((item, key) => {
          newOptions.push({
            id: item.placeId,
            name: item.address + " " + item.secondaryAddress,
            key: item.placeId,
          });
        });
        // let tempOptions = data.data.map((item, key) => {
        //   console.log("Inside tempOptions:", item);
        //   let obj = {
        //     id: item.placeId,
        //     name: item.address + " " + item.secondaryAddress,
        //   };
        //   console.log("Last line Inside tempOptions OBJ:", obj);
        //   return obj;
        // });
        console.log("newOptions:", newOptions);
        setOptions(newOptions);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    //console.log("onsearch", string, results);
  };

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result);
  };

  const handleOnPickupSelect = (item) => {
    // the item selected
    setPickup(item);
    localStorage.setItem("pickup", JSON.stringify(item));
    console.log("Pickup", item);
  };
  const handleOnDropoffSelect = (item) => {
    // the item selected
    setDropoff(item);
    console.log("DropOff", item);
    localStorage.setItem("dropoff", JSON.stringify(item));
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };
  const formatResult = (item, key) => {
    return (
      <>
        {/* <span style={{ display: "block", textAlign: "left" }}>
          id: {item.id}
        </span> */}
        <span key={item.id} style={{ display: "block", textAlign: "left" }}>
          {item.name}
        </span>
      </>
    );
  };
  return (
    <>
      <section id="slide" class="slide">
        <div class="content-bottom">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-6" data-aos="fade-right">
                <div class="form-outer">
                  <Form onSubmit={handleSubmit}>
                    <h4>Get your green estimate now</h4>

                    <div class="form-field-outer">
                      <div class="input-group-addon">
                        <i class="fas fa-phone-alt"></i>
                      </div>

                      <input
                        type="text"
                        placeholder="Mobile"
                        className="form-control-new"
                        maxLength={10}
                        minLength={10}
                        required
                      />
                    </div>

                    <div class="form-field-outer">
                      <div class="input-group-addon">
                        <i class="fas fa-map-marker-alt"></i>
                      </div>
                      <div style={{ zIndex: "9999", position: "relative" }}>
                        <ReactSearchAutocomplete
                          items={options}
                          onSearch={handleOnSearch}
                          onHover={handleOnHover}
                          onSelect={handleOnPickupSelect}
                          onFocus={handleOnFocus}
                          autoFocus
                          formatResult={formatResult}
                          name="pickup"
                          placeholder="Pick up  address"
                        />
                      </div>
                    </div>

                    <div class="form-field-outer">
                      <div class="input-group-addon">
                        <i class="fas fa-map-marker-alt"></i>
                      </div>
                      <ReactSearchAutocomplete
                        items={options}
                        onSearch={handleOnSearch}
                        onHover={handleOnHover}
                        onSelect={handleOnDropoffSelect}
                        onFocus={handleOnFocus}
                        autoFocus
                        formatResult={formatResult}
                        name="dropoff"
                        style={{ zIndex: -100 }}
                        placeholder="Delivery address"
                      />
                    </div>

                    <div>
                      <Button
                        className="btn-ne"
                        style={{ backgroundColor: "#54254E" }}
                        type="submit"
                      >
                        Estimate
                      </Button>
                    </div>
                  </Form>

                  {/* <Form onSubmit={handleSubmit}>
                    <input
                      type="text"
                      placeholder="Mobile"
                      maxLength={10}
                      minLength={10}
                      className="form-control-new"
                      required
                    />

                    <ReactSearchAutocomplete
                      items={options}
                      onSearch={handleOnSearch}
                      onHover={handleOnHover}
                      onSelect={handleOnPickupSelect}
                      onFocus={handleOnFocus}
                      autoFocus
                      formatResult={formatResult}
                      name="pickup"
                      className="form-control-new"
                    />
                    <ReactSearchAutocomplete
                      items={options}
                      onSearch={handleOnSearch}
                      onHover={handleOnHover}
                      onSelect={handleOnDropoffSelect}
                      onFocus={handleOnFocus}
                      autoFocus
                      formatResult={formatResult}
                      name="dropoff"
                      style={{ zIndex: -100 }}
                      className="form-control-new"
                    />
                    <Button
                      variant="primary"
                      type="submit"
                      style={{ backgroundColor: "#2E2F7D" }}
                      className="btn-ne"
                    >
                      Next
                    </Button>
                  </Form> */}
                </div>
              </div>

              <div class="col-md-6" data-aos="fade-left" data-aos-delay="200">
                <img
                  data-aos="fade-up"
                  data-aos-delay="200"
                  src="assets/img/mobile-1.png"
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
