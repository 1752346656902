import { useEffect, useState } from "react";

import {
  Container,
  Button,
  Row,
  Col,
  Card,
  Badge,
  Form,
} from "react-bootstrap";

export const LeftSide = () => {
  const [showEstimation, setshowEstimation] = useState(false);

  const [services, setServices] = useState([]);
  const [addresses, setAddresses] = useState("");
  const [vehicles, setVehicles] = useState([]);
  const [singleVehicle, setSingleVehicle] = useState("");
  const [order, setOrder] = useState({});
  const [cost, setCost] = useState(0);
  const [payload, setPayload] = useState({});
  const [thanks, setThanks] = useState(false);
  const [token, setToken] = useState(JSON.parse(localStorage.getItem("token")));
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    console.log("...Callling first useeeffect");
    setLoading(false);
    let customerId = localStorage.getItem("id");
    let localtoken = localStorage.getItem("token");
    console.log("customerId:", customerId);
    console.log("localtoken:", localtoken);
    let token = JSON.parse(localtoken);
    setToken(token);
    fetch(
      "https://rest.wadelivery.com/api/v1/customer/business/service?customerId=" +
        customerId,
      {
        method: "GET", // or 'PUT'
        headers: {
          Authorization: "Bearer " + JSON.parse(localtoken),
          "Content-Type": "application/json ,text/plain, */*",
          "Accept-Language": "en",
        },
      }
    )
      .then((response) => response.json())
      .then(async (data) => {
        console.log("Service Respone:", data);
        setServices(data.data);
        setshowEstimation(false);
        setVehicles([]);
        // let newOrderParam = {
        //   ...order,
        //   service: { id: data.data[0].id, options: [] },
        // };
        // delete newOrderParam.vehicleType;
        //setOrder(newOrderParam);
        console.log("data.data[0].id:", data.data[0].id);
        let newOrderParam = {
          ...order,
          service: { id: data.data[0].id, options: [] },
          //vehicleType: { id: vehicles[0].id, options: [] },
        };
        console.log("newOrderParam:", newOrderParam);

        setOrder(newOrderParam);
        // getEstimation();
        // setshowEstimation(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [isLoading]);

  useEffect(() => {
    console.log("services:", services);
    if (services.length > 0) {
      getVehicleTypes(services[0].id);
    }
  }, [services]);
  useEffect(() => {
    console.log("vehicles:", vehicles);
    if (vehicles.length > 0) {
      getEstimation();
    }
  }, [vehicles]);

  const setService = (e) => {
    console.log("e.target.value:", e.target.value);
    setVehicles([]);
    let existingOrder = order;
    existingOrder.service["id"] = e.target.value;
    setOrder(existingOrder);
    getVehicleTypes(e.target.value);
    console.log("setService New Order:", order);
  };

  const setVehicleOrder = async (e) => {
    console.log("e.target.name:", e.target.value);
    // let newOrderParam = {
    //   ...order,
    //   vehicleType: { id: e.target.value, options: [] },
    // };
    let existingOrder = order;
    existingOrder.vehicleType["id"] = e.target.value;
    setOrder(existingOrder);
    console.log("Set Vehicle existingOrder:", existingOrder);
    await setOrder(existingOrder);
    await setSingleVehicle(e.target.value);
    getEstimation();
  };

  const getVehicleTypes = async (serviceId) => {
    console.log("serviceId:", serviceId);
    fetch(
      "https://rest.wadelivery.com/api/v1/customer/business/service/vehicle-type/?latitude=18.53168104745512&longitude=73.87905952519232&serviceId=" +
        serviceId,
      {
        method: "GET", // or 'PUT'
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "Accept-Language": "en",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        setVehicles(data.data);
        let newOrderParam = {
          ...order,
          vehicleType: { id: data.data[0].id, options: [] },
        };
        setOrder(newOrderParam);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // useEffect(() => {
  //   console.log("Order:", order);
  //   getEstimation();
  // }, [singleVehicle]);

  const getEstimation = async () => {
    const payload = {
      isScheduled: false,
      promoCode: "",
      vehicleType: order.vehicleType,
      pickup: {
        coordinates: [73.87646044666799, 18.537677432994954],
        scheduleDateAfter: 0,
        scheduleDateBefore: 0,
        schedulePickupNow: false,
      },
      dropoffs: [
        {
          coordinates: [73.87646044666799, 18.537677432994954],
          scheduleDateAfter: 0,
          scheduleDateBefore: 0,
        },
      ],
      service: order.service,
      customerId: localStorage.getItem("id"),
    };
    console.log("payload:", payload);
    setPayload(payload);
    fetch(
      "https://rest.wadelivery.com/api/v1/customer/business/order/ondemand/calculate-price",
      {
        method: "POST", // or 'PUT'
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "Accept-Language": "en",
        },
        body: JSON.stringify(payload),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        setCost(data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const sendOrder = async () => {
    let userData = await localStorage.getItem("user");
    let user = JSON.parse(userData);
    let newOrder = {
      ...order,
      codAmount: cost.price,
      paymentMethod: "Cash",
      paymentSide: "Sender",
      customerId: localStorage.getItem("id"),
      pickup: {
        address: JSON.parse(localStorage.getItem("pickup")).name,
        coordinates: [73.79783479999999, 18.5986763],
        scheduleDateAfter: 0,
        scheduleDateBefore: 0,
        schedulePickupNow: false,
        // fullName: user.name,
        phone: user.mobile,
        // email: user.email,
      },
      dropoffs: [
        {
          address: JSON.parse(localStorage.getItem("dropoff")).name,
          coordinates: [73.79783479999999, 18.5986763],
          scheduleDateAfter: 0,
          scheduleDateBefore: 0,
        },
      ],
      promoCode: "",
      isScheduled: false,
    };
    fetch(
      "https://rest.wadelivery.com/api/v1/customer/business/order/ondemand/",
      {
        method: "POST", // or 'PUT'
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          "Accept-Language": "en",
        },
        body: JSON.stringify(newOrder),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        setThanks(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  if (!thanks) {
    return (
      <>
        <section id="slide" class="slide">
          <div class="content-bottom">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-md-6" data-aos="fade-right">
                  <div class="form-outer">
                    {/* <h4>Get your green estimate now</h4>

                  <div class="form-field-outer">
                    <div class="input-group-addon">
                      <i class="fas fa-map-marker-alt"></i>
                    </div>
                    <input
                      type="text"
                      class="form-control-new"
                      placeholder="Pick up address"
                    />
                  </div>

                  <div class="form-field-outer">
                    <div class="input-group-addon">
                      <i class="fas fa-map-marker-alt"></i>
                    </div>
                    <input
                      type="text"
                      class="form-control-new"
                      placeholder="Delivery address"
                    />
                  </div>

                  <div class="form-field-outer">
                    <div class="input-group-addon">
                      <i class="fas fa-phone-alt"></i>
                    </div>
                    <input
                      type="text"
                      class="form-control-new"
                      placeholder="Your Mobile No"
                    />
                  </div>

                  <div class="form-field-outer">
                    <div class="input-group-addon">
                      <i class="fas fa-archive"></i>
                    </div>
                    <input
                      type="text"
                      class="form-control-new"
                      placeholder="Your Requirement"
                    />
                  </div>

                  <p>
                    <a href="#" class="btn-new" tabindex="0">
                      <span>Estimate</span>
                    </a>
                  </p> */}
                    <Card className="bg-gray mb-2">
                      <Card.Header className="bg-gray">
                        {" "}
                        <h5>Select Service</h5>
                      </Card.Header>

                      <Card.Body>
                        <Card.Text>
                          <select
                            class="form-select"
                            aria-label=""
                            onChange={setService}
                            value={order.service ? order.service.id : ""}
                          >
                            <option selected>Please Select</option>
                            {services.length > 0 &&
                              services.map((item, i) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.title}
                                  </option>
                                );
                              })}
                          </select>
                        </Card.Text>
                      </Card.Body>
                    </Card>

                    <Card className="bg-gray mb-2">
                      <Card.Header className="bg-gray">
                        {" "}
                        <h5>Select Vehicle</h5>
                      </Card.Header>

                      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                      <Card.Body>
                        {/* <Card.Title className="bg-secondary">Login</Card.Title> */}
                        <Card.Text>
                          <select
                            class="form-select"
                            aria-label=""
                            onChange={setVehicleOrder}
                            value={
                              order.vehicleType ? order.vehicleType.id : ""
                            }
                          >
                            <option selected>Please Select</option>
                            {vehicles.length > 0 &&
                              vehicles.map((item, i) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.title}
                                  </option>
                                );
                              })}
                          </select>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Card className="bg-gray mb-2">
                      <Card.Header className="bg-gray">
                        {" "}
                        <h5>Cost Estimation</h5>
                      </Card.Header>
                      <Card.Body>
                        <Card.Text>
                          <div className="d-flex justify-content-between align-items-center ">
                            <div className="cost-title">
                              {" "}
                              {cost.currencyCode} {cost.price}
                            </div>
                            <div>
                              <Button
                                variant="primary"
                                type="submit"
                                className="btn-ne "
                                style={{
                                  backgroundColor: "#54254E",
                                  fontSize: "18px",
                                }}
                                onClick={sendOrder}
                              >
                                Submit
                              </Button>
                            </div>
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </div>

                <div class="col-md-6" data-aos="fade-left" data-aos-delay="200">
                  <img
                    data-aos="fade-up"
                    data-aos-delay="200"
                    src="assets/img/mobile-1.png"
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  */}
      </>
    );
  } else {
    return (
      <>
        <section id="slide" class="slide">
          <div class="content-bottom">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-md-6" data-aos="fade-right">
                  <div class="form-outer text-center">
                    <div>
                      <h1>Thank You!</h1>
                    </div>
                    <Button
                      style={{
                        backgroundColor: "#54254E",
                        fontSize: "18px",
                      }}
                      onClick={() => {
                        setThanks(false);
                        localStorage.clear();
                        window.location.reload();
                      }}
                    >
                      Click here for new estimation
                    </Button>
                  </div>
                </div>

                <div class="col-md-6" data-aos="fade-left" data-aos-delay="200">
                  <img
                    data-aos="fade-up"
                    data-aos-delay="200"
                    src="assets/img/mobile-1.png"
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
};
