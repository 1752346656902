import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { LeftSide } from "./LeftSide";
import { MainContent } from "./MainContent";

import { Container, Row, Col, Button } from "react-bootstrap";

export const MainLayout = () => {
  return (
    <>
      <LeftSide />
    </>
  );
};
