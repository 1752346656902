import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
export default function Protected(props) {
  let navigate = useNavigate();
  const { Component } = props;
  useEffect(() => {
    let user = localStorage.getItem("user");
    if (!user) {
      navigate("/login");
    }
  }, []);

  return (
    <>
      <Component />
    </>
  );
}
